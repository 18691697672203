<template>
  <div class="app-accordion">
    <button
      type="button"
      class="app-accordion__button"
      @click="toggleShowContent"
    >
      <span class="app-accordion__button-content">
        <slot name="summary" />
      </span>

      <icon :name="icon" class="app-accordion__button-icon" />
    </button>

    <div
      ref="accordion-content"
      class="app-accordion__content"
      :class="{
        'app-accordion__content--expanded': showContent,
      }"
      :style="{
        '--accordion-content-height': contentHeight,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce'

  export default {
    emits: ['toggle'],
    data() {
      return {
        contentHeight: 0,
        showContent: false,
        onResizeDebounce: debounce(this.onResize, 100),
      }
    },
    computed: {
      icon() {
        return this.showContent
          ? 'ic:outline-minus'
          : 'ic:outline-plus'
      },
    },
    mounted() {
      this.setExpandedHeight()
      window.addEventListener('resize', this.onResizeDebounce)
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.onResizeDebounce)
    },
    methods: {
      toggleShowContent() {
        this.showContent = !this.showContent
        this.$emit('toggle')
      },
      setExpandedHeight() {
        const contentElement = this.$refs['accordion-content']

        if (!contentElement) {
          return
        }

        const propertyValue = getComputedStyle(contentElement).getPropertyValue('--accordion-bottom-spacing')
        const bottomSpacing = parseInt(propertyValue)

        this.contentHeight = `${contentElement.scrollHeight + bottomSpacing}px`
      },
      onResize() {
        this.showContent = false
        this.setExpandedHeight()
      },
    },
  }
</script>

<style lang="scss">
.app-accordion {
  border-bottom: 1px solid var(--color-light-gray);
}

.app-accordion__button {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: space-between;
  padding: var(--spacing-small) 0;
  font-size: var(--font-size-default);
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
}

.app-accordion__button-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.app-accordion__button-icon {
  flex-shrink: 0;
}

.app-accordion__content {
  --accordion-bottom-spacing: var(--spacing-default);

  height: 0;
  overflow: hidden;
  transition: height .1s ease-in;
}

.app-accordion__content--expanded {
  height: var(--accordion-content-height);
}
</style>
